import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"

/* Import Global Hook(s) */
import useFirebaseUser from "~hooks/useFirebaseUser"
import { useHomePage } from "~queries/hooks"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Styles */
import "./navigation-links.scss"

const Links = ({ pathname }) => {
  
  const { displayProgram, displayTickets, displayEdits, displayFairGuide, fairGuideButton, displayHotel } = useHomePage()

  const hide = true

  return (
    <ul className={classnames("navigation__links")}>
      <li className="navigation__links__link navigation__links__home">
        <Link to="/">Untitled Art</Link>
      </li>


      {!hide && pathname !== "/fair-guide" && (
        <li className="navigation__links__link">
          <Link to="/fair-guide">Fair Guide</Link>
        </li>
      )}


      {(
        <li className="navigation__links__link">
          <Link to="/about">About</Link>
        </li>
      )}
      {(
        <li className="navigation__links__link">
          <Link to="/galleries">Galleries</Link>
        </li>
      )}
      {(
        <li className="navigation__links__link">
          <Link to="/visit">Visit</Link>
        </li>
      )}
      {(
        <li className="navigation__links__link navigation__break">
          <Link to="/partners">Partners</Link>
        </li>
      )}



      {!hide && pathname !== "/program" && displayProgram && (
        <li className="navigation__links__link">
          <Link to="/program">Program</Link>
        </li>
      )}
      {!hide && pathname !== "/virtual-fair" && (
        <li className="navigation__links__link">
          <Link to="/virtual-fair">Virtual Fair</Link>
        </li>
      )}



      {(
        <li className="navigation__links__link">
          <Link to="/edits">Untitled Edit</Link>
        </li>
      )}
      {(
        <li className="navigation__links__link">
          <Link to="/news">News</Link>
        </li>
      )}
      {(
        <li className="navigation__links__link">
          <Link to="/podcast">Podcast</Link>
        </li>
      )}
    </ul>
  )
}

export default Links
