import React, { useState, useEffect, useContext } from "react"
import classnames from "classnames"
import slug from "slug"
import { useScrollDirection } from "react-use-scroll-direction"
import throttle from "lodash.throttle"

/* Import Global Context(s) */
import HeaderContext from "~context/header"

/* Import Local Components */
import DesktopNavigation from "./components/desktopNavigation/desktopNavigation"
import MobileNavigation from "./components/mobileNavigation/mobileNavigation"

/* Import Local Style(s) */
import "./navigation.scss"

const Navigation = ({ location: { pathname }, className }) => {
  const [isHeaderReady] = useContext(HeaderContext)
  const [navigationVisible, setNavigationVisible] = useState(false)
  const [navigationFixed, setNavigationFixed] = useState(false)
  const { isScrollingUp, isScrollingDown } = useScrollDirection()

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY <= 0) {
          setNavigationVisible(false)
          setNavigationFixed(false)
        }
      }
    }
    const throttledHandleScroll = throttle(handleScroll, 66)
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", throttledHandleScroll)
    }
    return () => {
      window.removeEventListener("resize", throttledHandleScroll)
    }
  }, [])

  useEffect(() => {
    if (isScrollingUp) {
      setNavigationVisible(true)
      if (typeof window !== "undefined") {
        if (window.scrollY >= 100) {
          setNavigationFixed(true)
        }
      }
    }
    if (isScrollingDown) {
      setNavigationVisible(false)
      if (typeof window !== "undefined") {
        if (window.scrollY >= 100) {
          setNavigationFixed(true)
        } else {
          setNavigationFixed(false)
        }
      }
    }
  }, [isScrollingUp, isScrollingDown, setNavigationVisible])

  if (
    !isHeaderReady ||
    pathname.includes("/podcast/podcast") ||
    pathname.includes("/program/event") ||
    pathname.includes("/news/post")
  ) {
    return null
  }

  return (
    <nav
      className={classnames(
        "navigation",
        className,
        `navigation--${slug(pathname)}`,
        {
          "show-site-navigation": navigationVisible,
          "is-navigation-visible": true, //navigationVisible
          "is-navigation-fixed": true, //navigationFixed
          "is-home": pathname === "/",
        }
      )}
    >
      <MobileNavigation pathname={pathname} />
      {/*<DesktopNavigation pathname={pathname} />*/}
    </nav>
  )
}

export default Navigation
