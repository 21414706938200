import React, { useContext } from "react"

import classnames from "classnames"
import PropTypes from "prop-types"
import slug from "slug"

/* Import Global Context(s) */
import HeaderContext from "~context/header"

/* Import Global Component(s) */
import Navigation from "~components/navigation/navigation"

/* Import Local Styles */
import "./page-header.scss"

const PageHeader = ({ title, className = null, location }) => {
  const [isHeaderReady] = useContext(HeaderContext)
  return (
    <>
      <header
        className={classnames(
          `page-header page-header--${slug(title)}`,
          className,
          { "is-intro-done": isHeaderReady }
        )}
      >
        <h1>{title}</h1>
        <Navigation location={location} />
      </header>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default PageHeader
