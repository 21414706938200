import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Assets */
import HomeSvgSrc from "~assets/images/home.svg"

/* Import Local Styles */
import "./home-icon-link.scss"

const HomeIconLink = () => (
  <Link to="/" className="navigation__home">
    <img src={HomeSvgSrc} alt="Home" />
  </Link>
)

export default HomeIconLink
