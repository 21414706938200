import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

import { useHomePage } from "~queries/hooks"

import "./dot-links.scss"


const DotLinks = ({ pathname }) => {

	const { displayProgramEvents, displayTickets, displayFairGuide, fairGuideButton, displayVirtual, displayHotel, hotelButton } = useHomePage()

	return (
		<aside className="dot_links">
			<ul>
				<li><br /></li>
				{displayFairGuide && <li>
					<Link to="/fair-guide">
						<span className="link_circle"></span>
						<span>Fair Guide</span>
					</Link>	
				</li>}
				{displayProgramEvents && <li>
					<Link to="/program">
						<span className="link_circle"></span>
						<span>Program</span>
					</Link>
				</li>}
				{displayVirtual && <li>
					<Link to="/virtual-fair">
						<span className="link_circle"></span>
						<span>Virtual Fair</span>
					</Link>	
				</li>}
				{displayTickets && <li>
					<Link to="/tickets">
						<span className="link_circle buy_tickets" style={{backgroundColor: 'var(--color-yellow)'}}></span>
						<span>Buy Tickets</span>
					</Link>	
				</li>}

				{displayHotel && (<li>
					<Link to="/travel">
						<span 
							className="link_circle"
							style={{backgroundColor: hotelButton?.bcolor?.hex }}
						></span>
						<span>Travel</span>
					</Link>	
				</li>)}
			</ul>
		</aside>
	)

}

export default DotLinks