import React, { useState } from "react"
import { navigate } from "@reach/router"
import { motion, AnimatePresence } from "framer-motion"
import { useLockBodyScroll } from "react-use"
import classnames from "classnames"

/* Import Global Components */
import Footer from "~components/footer/footer"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Global Asset(s) */
import CrossSvgSrc from "~assets/images/close.svg"
import HamburgerSvgSrc from "~assets/images/hamburger.svg"

import HamburgerSvgSrcDesktop from "~assets/images/hamburger-desktop.svg"

/* Import Local Components */
import HomeIconLink from "../homeIconLink/homeIconLink"
import VIPNavigation from "../vipNavigation/vipNavigation"
import Links from "../navigationLinks/navigationLinks"
import DotLinks from '../dotLinks/dotLinks'

/* Import Local Style(s) */
import "./mobile-navigation.scss"

const MobileNavigation = ({ pathname }) => {
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false)
  const pageTitles = {
    "/": "Untitled Art",
    "/program": "Program",
    "/program/": "Program",
    "/about": "About",
    "/about/": "About",
    "/news": "News",
    "/news/": "News",
    "/edits": "Untitled Edit",
    "/edits/": "Untitled Edit",
    "/podcast": "Podcast",
    "/podcast/": "Podcast",
    "/visit": "Visit",
    "/visit/": "Visit",
    
    "/travel": "Travel",
    "/travel/": "Travel",

    "/tickets": "Tickets",
    "/tickets/": "Tickets",

    "/partners": "Partners",
    "/partners/": "Partners",
    "/galleries": "Galleries",
    "/galleries/": "Galleries",
    "/vip": "VIP",
    "/vip/": "VIP",
    "/vip/reset": "VIP Reset",
    "/vip/reset/": "VIP Reset",
    "/vip/profile": "VIP",
    "/vip/profile/": "VIP",
    "/vip/profile/edit": "VIP",
    "/vip/profile/edit/": "VIP",
    "/virtual-fair": "Virtual Fair",
    "/virtual-fair/": "Virtual Fair",
    "/fair-guide": "Fair Guide",
    "/fair-guide/": "Fair Guide",
  }
  const mobileTitle = pageTitles[pathname] || "Untitled Art"

  useLockBodyScroll(isMobileNavigationOpen)


  const toggleMenu = () => {
    navigate(pathname)
    setIsMobileNavigationOpen(!isMobileNavigationOpen);
    
  }


  return (
    <div
      className={classnames("mobile-navigation", {
        "is-mobile-navigation-open": isMobileNavigationOpen,
      })}
    >
      <HomeIconLink />
      <VIPNavigation />

      <div className="mobile-navigation__links-container">
        <div className="menu_wrapper">
          <DotLinks pathname={pathname} />
          <Links pathname={pathname} />  
        </div>
        
        <Footer hideLegal={true} />
      </div>
      <button
        className="navigation__hamburger"
        onClick={toggleMenu}
      >
        <AnimatePresence exitBeforeEnter>
          {!isMobileNavigationOpen && (
            <motion.img
              src={HamburgerSvgSrc}
              alt="hamburger"
              key="hamburger"
              initial="initial"
              animate="animate"
              exit="exit"
              className="mobile-only"
              variants={elementTransitionAnimation.variants}
            />
          )}
          {!isMobileNavigationOpen && (
            <motion.img
              src={HamburgerSvgSrcDesktop}
              alt="hamburger"
              key="hamburgertwo"
              initial="initial"
              animate="animate"
              className="desktop-only"
              exit="exit"
              variants={elementTransitionAnimation.variants}
            />
          )}

          {isMobileNavigationOpen && (
            <motion.img
              src={CrossSvgSrc}
              className="close"
              alt="cross"
              key="cross"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={elementTransitionAnimation.variants}
            />
          )}
        </AnimatePresence>
      </button>
      <div className="navigation__mobile-title">{mobileTitle}</div>
      <div className="navigation__gradient" />
    </div>
  )
}

export default MobileNavigation
