import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"


/* Import Local Styles */
import "./vip-navigation.scss"

const VIPNavigation = () => (
  <Link to="/vip" className="navigation__vip">
    <span>VIP</span>
  </Link>
)

export default VIPNavigation
